



















import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue } from "vue-property-decorator";

const CurrencySelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    useId: {
      type: Boolean,
      default: false,
    },
  },
});

/**
 * @deprecated
 * new component {@link SelectCurrency}
 */
@Component
export default class CurrencySelect extends CurrencySelectProps {
  currencyOption = {
    data: [] as DataMasterCurrency[],
    search: "",
    fetching: true,
  };

  created(): void {
    this.getCurrencyList();
  }

  getCurrencyList(): void {
    this.currencyOption.fetching = true;
    const param: RequestQueryParamsModel = {
      limit: 20,
      page: 0,
    };
    settingsServices
      .listOfMasterCurrency(param, "")
      .then(res => {
        this.currencyOption.data = res.data;
      })
      .finally(() => (this.currencyOption.fetching = false));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  filterOption(input, option): boolean {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  handleChange(e: string): void {
    if (e) {
      this.getCurrencyMeta(e);
    }
    this.$emit("input", e);
    this.$emit("change", e);
  }

  getCurrencyMeta(currencyCode: string): void {
    const selectedCurrency = this.currencyOption.data.find(
      c => c.currencyCode === currencyCode
    );
    if (selectedCurrency) {
      this.$emit("currencyChange", selectedCurrency);
    } else {
      this.$emit("currencyChange", null);
    }
  }
}
