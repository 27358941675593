var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        "show-search": "",
        filterOption: _vm.filterOption,
        allowClear: true,
        loading: _vm.currencyOption.fetching,
        value: _vm.value,
        disabled: _vm.disabled
      },
      on: { change: _vm.handleChange, input: _vm.handleChange }
    },
    _vm._l(_vm.currencyOption.data, function(item, index) {
      return _c(
        "a-select-option",
        {
          key: index,
          attrs: { value: _vm.useId ? item.id : item.currencyCode }
        },
        [_vm._v(_vm._s(item.currencyCode))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }