
























































































































































































import { Vue, Component } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import {
  ResponseListOfMasterUom,
  DataUnit,
  DataListUom,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { debounce, debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { uomReferenceService } from "@/services/uom-reference.service";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { UOMReferenceResponseDto } from "@/models/interface/uom-reference/UOMReferenceResponseDto.interface";
import SearchBuilder from "@/builder/SearchBuilder";

@Component
export default class UOM extends Vue {
  dataListUom = {} as ResponseListOfMasterUom;
  direction = "" as string;
  trigerdisable = false as boolean;
  typemodal = "" as string;
  titlemodalOpen = "lbl_uom" as string;
  switchActive = true as boolean;
  authPrivilege = [] as string[];
  selectedRowKeys = [] as number[];
  page = 1 as number;
  limit = 10 as number;
  search = "" as string;
  unitValue = "" as string;
  dataListUnit = [] as DataUnit[];
  uomCodeOptions: Option<UOMReferenceResponseDto>[] = [];
  tabList = [
    {
      key: "dataunit",
      tab: "Data Unit",
    },
  ];
  key = "dataunit";
  modalOpen = false as boolean;
  isFormSubmitted = false as boolean;
  form = this.$form.createForm(this, { name: "uom" });
  formRules = {
    unit: {
      label: "lbl_unit",
      name: "unit",
      placeholder: "lbl_unit_placeholder",
      decorator: [
        "unit",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    name: {
      label: "lbl_name",
      name: "name",
      placeholder: "lbl_name_placeholder",
      decorator: [
        "name",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    code: {
      label: "lbl_code",
      name: "code",
      placeholder: "lbl_code_placeholder",
      decorator: [
        "code",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "description",
      placeholder: "lbl_description_placeholder",
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    active: {
      label: "lbl_active",
      name: "active",
      decorator: ["active"],
    },
    base: {
      label: "lbl_base_unit",
      name: "base",
      decorator: ["base"],
    },
  };
  columnsTable = [
    {
      title: this.$root.$t("lbl_name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 60,
    },
    {
      title: this.$root.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: 60,
    },
    {
      title: this.$t("lbl_code"),
      dataIndex: "codeDescriptionRef",
      key: "codeDescriptionRef",
      sorter: true,
      width: 60,
    },
    {
      title: this.$t("lbl_unit_detail"),
      dataIndex: "unit",
      key: "unit",
      sorter: true,
      width: 60,
    },
    {
      title: this.$root.$t("lbl_active"),
      dataIndex: "active",
      key: "active",
      width: 60,
      scopedSlots: { customRender: "isEnable" },
    },
    {
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["update"],
      width: 120,
      align: "center",
      fixed: "right",
    },
  ] as ColumnTableCustom[];
  columnsTableModal = [
    {
      title: this.$t("lbl_base_unit"),
      dataIndex: "baseUomName",
      key: "base",
      sorter: true,
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_value"),
      dataIndex: "conversionRate",
      key: "value",
      sorter: true,
      width: 100,
      scopedSlots: { customRender: "conversionRate" },
    },
    {
      title: this.$t("lbl_unit"),
      dataIndex: "uomId",
      key: "unit",
      sorter: true,
      width: 100,
      scopedSlots: { customRender: "uomId" },
      responsiveColSelect: [
        {
          name: "uomId",
          placeholder: this.$t("lbl_unit"),
          style: "width: 100%;",
          disabled: "",
          value: "id",
          options: [] as DataListUom[],
        },
      ],
      responsiveColInput: [
        {
          name: "conversionRate",
          placeholder: this.$t("lbl_value"),
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
  ] as ColumnTableCustom[];
  loading = {
    getUomReferences: false,
  };

  mounted() {
    this.getListOfUomCodes = debounceProcess(this.getListOfUomCodes);

    this.getListOfUom("");
    this.getListOfUomCodes("");
  }
  handleSelectTable(value, key, col, _recordOptions): void {
    this.dataListUnit[key] = { ...this.dataListUnit[key], [col]: value };
    this.dataListUnit = this.dataListUnit.slice();
  }
  handleInput(value, key, objectColInput) {
    this.dataListUnit[key][objectColInput.name] = value;
    this.dataListUnit = this.dataListUnit.slice();
  }
  unitValueChange(_e): void {
    this.dataListUnit = [];
  }
  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  handleDeleteRow() {
    this.dataListUnit = this.dataListUnit.filter(data => {
      return !this.selectedRowKeys.includes(data.key);
    });
    this.dataListUnit.forEach((data, index) => (data.key = index));
    this.dataListUnit = this.dataListUnit.slice();
    this.selectedRowKeys = [];
  }
  handleAddRow(): void {
    this.dataListUnit = [
      ...this.dataListUnit,
      {
        id: null,
        key: this.dataListUnit.length,
        baseUomName: this.form.getFieldValue("unit"),
        conversionRate: null,
        uomId: null,
      },
    ];
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  reponseSearchInput(response): void {
    if (response) this.search = "name~*" + response + "*";
    else this.search = "";
    this.getListOfUom("");
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.getListOfUom("");
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.getListOfUom("");
  }
  reponseEditTable(response): void {
    this.trigerdisable = false;
    this.typemodal = "edit";
    this.titlemodalOpen = "lbl_edit_uom";
    this.dataDetail = "/" + response.data.id;
    this.ResetFilter(false);
    this.getListOfUom("/" + response.data.id);
  }
  reponseDeleteTable(response): void {
    const path = "/" + response.data.id;
    settingsServices.deleteMasterUom(path).then(() => {
      this.ResetFilter(false);
      this.getListOfUom("");
    });
  }
  onTabChange(key, type) {
    this[type] = key;
  }
  handleSubmit(e: Event): void {
    e.preventDefault();
  }
  createNew(): void {
    this.modalOpen = true;
    this.typemodal = "create";
    this.form.resetFields();
    this.titlemodalOpen = "lbl_create_new_uom";
    this.ResetFilter(false);
  }
  submitForm(e: Event, type: string): void {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        const dataConversion = [] as any;
        const datapost = {
          active: this.switchActive,
          unit: values.unit,
          conversionCreateRequestDTO: [],
          name: values.name,
          description: values.description,
          codeRefId: values.code?.key,
        };
        this.isFormSubmitted = true;
        this.dataListUnit.forEach(item => {
          if (item.baseUomName && item.conversionRate && item.uomId)
            dataConversion.push({
              baseUomName: item.baseUomName,
              conversionRate: item.conversionRate,
              uomId: item.uomId,
            });
        });
        if (dataConversion.length > 0) {
          datapost.conversionCreateRequestDTO = dataConversion;
        }
        switch (type) {
          case "create":
            settingsServices
              .createUom(datapost)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfUom("");
                  this.form.resetFields();
                  this.$notification.success({
                    description: Messages.CREATE_SUCCESS,
                    message: "Success",
                    duration: 30,
                  });
                }
              })
              .catch(error => {
                this.isFormSubmitted = false;
                this.$notification.error({
                  description: error.details,
                  message: "Error",
                  duration: 30,
                });

                this.$notification.error({
                  description: Messages.CREATE_FAIL,
                  message: "Error",
                  duration: 30,
                });
              })

              .finally(() => (this.isFormSubmitted = false));
            break;
          case "edit":
            settingsServices
              .updateUom(datapost, this.dataDetail)
              .then(res => {
                if (res) {
                  this.modalOpen = false;
                  this.getListOfUom("");
                  this.form.resetFields();
                  this.$notification.success({
                    description: Messages.UPDATE_SUCCESS,
                    message: "Success",
                    duration: 30,
                  });
                }
              })
              .catch(error => {
                this.isFormSubmitted = false;
                this.$notification.error({
                  description: error.details,
                  message: "Error",
                  duration: 30,
                });

                this.$notification.error({
                  description: Messages.UPDATE_FAIL,
                  message: "Error",
                  duration: 30,
                });
              })

              .finally(() => (this.isFormSubmitted = false));
            break;
          default:
            break;
        }
      } else {
        this.$notification["error"]({
          message: "Error",
          description: "Form is mandatory",
        });
      }
    });
  }
  handleCancel(): void {
    this.modalOpen = false;
  }
  handleUomRefenceSearch(search: string): void {
    this.getListOfUomCodes(search);
  }
  onSorterChange(response): void {
    if (response.sort.order) {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
    } else {
      this.ResetFilter(false);
    }
    this.getListOfUom("");
  }
  getListOfUom(path: string): void {
    let params;
    if (!path) {
      params = {
        limit: this.limit,
        page: this.page - 1,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
    }
    this.loadingTable = true;
    settingsServices
      .listOfMasterUom(params, path)
      .then(res => {
        if (!path) {
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListUom = res;
          this.getListOfBaseUnit();
        } else {
          this.dataListUnit = res.conversionResponseDTO
            ? res.conversionResponseDTO
            : [];

          debounce(() => {
            const code =
              res.codeRefId && res.codeDescriptionRef
                ? {
                    key: res.codeRefId,
                    label: res.codeDescriptionRef,
                  }
                : undefined;
            this.form.setFieldsValue({
              unit: res.unit,
              name: res.name,
              description: res.description,
              code: code,
            });
            this.switchActive = res.active;
          }, 200);
          this.modalOpen = true;
        }
      })
      .finally(() => (this.loadingTable = false));
  }
  getListOfUomCodes(search: string): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (search) {
      params.search = builder
        .push(["code", search], { like: "both" })
        .or()
        .push(["description", search], { like: "both" })
        .build();
    }

    this.loading.getUomReferences = true;
    uomReferenceService
      .getUomReferences(params)
      .then(res => {
        this.uomCodeOptions = res.data.map(item => ({
          key: item.id,
          label: item.codeDescription,
          value: item.id,
          meta: item,
        }));
      })
      .finally(() => {
        this.loading.getUomReferences = false;
      });
  }
  getListOfBaseUnit(): void {
    let params;
    params = {
      baseUnit: true,
    } as RequestQueryParamsModel;
    settingsServices.listOfBaseUnit(params).then(res => {
      res.data.forEach((dataObject, index) => (dataObject.key = index));
      const findColumn = this.columnsTableModal.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn) {
        findColumn[0].options = res.data;
      }
    });
  }
  ResetFilter(getlist: boolean): void {
    this.direction = "";
    this.search = "";
    if (getlist) this.getListOfUom("");
  }
}
