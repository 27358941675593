import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { UOMReferenceResponseDto } from "@/models/interface/uom-reference/UOMReferenceResponseDto.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class UOMReferenceService extends HttpClient {
  getUomReferences(
    params: RequestQueryParamsModel
  ): Promise<Pagination<UOMReferenceResponseDto>> {
    return this.get<Pagination<UOMReferenceResponseDto>>(Api.UOMReference, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const uomReferenceService = new UOMReferenceService();
