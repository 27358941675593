var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: _vm.$t("lbl_unit_of_measurement") } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 16 } },
                                        [
                                          _c("InputSearch", {
                                            staticStyle: { width: "98%" },
                                            on: {
                                              "input-search":
                                                _vm.reponseSearchInput
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.ResetFilter(true)
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              cursor: "pointer",
                                              "font-size": "large",
                                              transform: "scaleX(-1)",
                                              "-moz-transform": "scaleX(-1)",
                                              "-webkit-transform": "scaleX(-1)",
                                              "-ms-transform": "scaleX(-1)"
                                            },
                                            attrs: { type: "reload" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12, align: "end" } },
                                    [
                                      _vm.$can("create", "product-uom")
                                        ? _c(
                                            "a-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.createNew()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("lbl_create_new")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  idtable: "table1",
                                  onSelectChange: null,
                                  selectedRowKeys: null,
                                  dataSource: _vm.dataListUom.data,
                                  columns: _vm.columnsTable,
                                  scroll: { x: 1000 },
                                  paginationcustom: true,
                                  defaultPagination: false
                                },
                                on: {
                                  "on-delete": _vm.reponseDeleteTable,
                                  "on-edit": _vm.reponseEditTable,
                                  "on-tablechange": _vm.onSorterChange
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total: _vm.dataListUom.totalElements,
                                      pageSizeSet: _vm.limit,
                                      idPagination: "pagination1"
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 12, align: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.dataListUom.totalElements) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 520,
            title: _vm.$t(_vm.titlemodalOpen),
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      staticClass: "bordertabs p-2",
                      attrs: { tab: _vm.$t("lbl_unit_data") }
                    },
                    [
                      _c(
                        "a-form",
                        {
                          attrs: {
                            form: _vm.form,
                            "label-col": { span: 5 },
                            "wrapper-col": { span: 18 }
                          },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitForm.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.unit.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.formRules.unit.decorator,
                                            expression:
                                              "formRules.unit.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.unit.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.unit.placeholder
                                          ),
                                          autocomplete: "off"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.unitValueChange($event)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.name.label)
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.formRules.name.decorator,
                                            expression:
                                              "formRules.name.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.name.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.name.placeholder
                                          ),
                                          autocomplete: "off"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(_vm.formRules.code.label)
                                      }
                                    },
                                    [
                                      _c("a-select", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: _vm.formRules.code.decorator,
                                            expression:
                                              "formRules.code.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.code.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.code.placeholder
                                          ),
                                          options: _vm.uomCodeOptions,
                                          loading: _vm.loading.getUomReferences,
                                          "filter-option": false,
                                          autocomplete: "off",
                                          "label-in-value": "",
                                          "show-search": "",
                                          "allow-clear": ""
                                        },
                                        on: {
                                          search: _vm.handleUomRefenceSearch
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.description.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-input", {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value:
                                              _vm.formRules.description
                                                .decorator,
                                            expression:
                                              "formRules.description.decorator"
                                          }
                                        ],
                                        attrs: {
                                          name: _vm.formRules.description.name,
                                          placeholder: _vm.$t(
                                            _vm.formRules.description
                                              .placeholder
                                          ),
                                          autocomplete: "off"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          _vm.formRules.active.label
                                        )
                                      }
                                    },
                                    [
                                      _c("a-switch", {
                                        attrs: {
                                          checkedChildren: _vm.$t("lbl_active"),
                                          unCheckedChildren: _vm.$t(
                                            "lbl_inactive"
                                          ),
                                          name: _vm.formRules.active.name
                                        },
                                        model: {
                                          value: _vm.switchActive,
                                          callback: function($$v) {
                                            _vm.switchActive = $$v
                                          },
                                          expression: "switchActive"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              (_vm.typemodal === "create" || _vm.typemodal === "edit") &&
              (_vm.$can("create", "product-uom") ||
                _vm.$can("update", "product-uom"))
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: {
                        type: "primary",
                        loading: _vm.isFormSubmitted,
                        "html-type": "submit"
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitForm($event, _vm.typemodal)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }